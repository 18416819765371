import React from "react";
import { useRouter } from "next/router";
import classNames from "classnames";
import { MENU_ROUTE } from "../../../utils/routes";

export function SVGDelete({ stroke }: { stroke?: string }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33203 3.9987C1.33203 3.63051 1.63051 3.33203 1.9987 3.33203H13.9987C14.3669 3.33203 14.6654 3.63051 14.6654 3.9987C14.6654 4.36689 14.3669 4.66536 13.9987 4.66536H1.9987C1.63051 4.66536 1.33203 4.36689 1.33203 3.9987Z"
        fill={stroke ?? "white"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66797 2.0013C6.49116 2.0013 6.32159 2.07154 6.19656 2.19656C6.07154 2.32159 6.0013 2.49116 6.0013 2.66797V3.33464H10.0013V2.66797C10.0013 2.49116 9.93106 2.32159 9.80604 2.19656C9.68102 2.07154 9.51145 2.0013 9.33463 2.0013H6.66797ZM11.3346 3.33464V2.66797C11.3346 2.13754 11.1239 1.62883 10.7488 1.25376C10.3738 0.878682 9.86507 0.667969 9.33463 0.667969H6.66797C6.13754 0.667969 5.62883 0.878682 5.25376 1.25376C4.87868 1.62883 4.66797 2.13754 4.66797 2.66797V3.33464H3.33464C2.96645 3.33464 2.66797 3.63311 2.66797 4.0013V13.3346C2.66797 13.8651 2.87868 14.3738 3.25376 14.7488C3.62883 15.1239 4.13754 15.3346 4.66797 15.3346H11.3346C11.8651 15.3346 12.3738 15.1239 12.7488 14.7488C13.1239 14.3738 13.3346 13.8651 13.3346 13.3346V4.0013C13.3346 3.63311 13.0362 3.33464 12.668 3.33464H11.3346ZM4.0013 4.66797V13.3346C4.0013 13.5114 4.07154 13.681 4.19656 13.806C4.32159 13.9311 4.49116 14.0013 4.66797 14.0013H11.3346C11.5114 14.0013 11.681 13.9311 11.806 13.806C11.9311 13.681 12.0013 13.5114 12.0013 13.3346V4.66797H4.0013Z"
        fill={stroke ?? "white"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.33464 6.66797C9.70283 6.66797 10.0013 6.96645 10.0013 7.33464V11.3346C10.0013 11.7028 9.70283 12.0013 9.33464 12.0013C8.96645 12.0013 8.66797 11.7028 8.66797 11.3346V7.33464C8.66797 6.96645 8.96645 6.66797 9.33464 6.66797Z"
        fill={stroke ?? "white"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66667 6.66797C7.03486 6.66797 7.33333 6.96645 7.33333 7.33464V11.3346C7.33333 11.7028 7.03486 12.0013 6.66667 12.0013C6.29848 12.0013 6 11.7028 6 11.3346V7.33464C6 6.96645 6.29848 6.66797 6.66667 6.66797Z"
        fill={stroke ?? "white"}
      />
    </svg>
  );
}

export function SVGDots({ isActive }: { isActive: boolean }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames("svg-dots", isActive && "dots-active")}
    >
      <rect width="24" height="24" rx="12" />
      <path
        d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
        stroke={"#0F172A"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
        stroke={"#0F172A"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
        stroke={"#0F172A"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SVGArrow({ className }: { className?: string }) {
  return (
    <svg
      width="11"
      height="9"
      viewBox="0 0 11 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M0.5 3.5L4 7.5L10 0.5" stroke="white" />
    </svg>
  );
}

export function SVGArrowLeft() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 6.70459C14.8132 6.51734 14.5595 6.41211 14.295 6.41211C14.0305 6.41211 13.7769 6.51734 13.59 6.70459L9.00002 11.2946C8.61002 11.6846 8.61002 12.3146 9.00002 12.7046L13.59 17.2946C13.98 17.6846 14.61 17.6846 15 17.2946C15.39 16.9046 15.39 16.2746 15 15.8846L11.12 11.9946L15 8.11459C15.39 7.72459 15.38 7.08459 15 6.70459Z"
        fill="black"
      />
    </svg>
  );
}

export function SVGEdit({
  className,
  color,
}: {
  className?: string;
  color?: string;
}) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_929_107942)">
        <path
          d="M7.33398 2.66699H2.66732C2.3137 2.66699 1.97456 2.80747 1.72451 3.05752C1.47446 3.30756 1.33398 3.6467 1.33398 4.00033V13.3337C1.33398 13.6873 1.47446 14.0264 1.72451 14.2765C1.97456 14.5265 2.3137 14.667 2.66732 14.667H12.0007C12.3543 14.667 12.6934 14.5265 12.9435 14.2765C13.1935 14.0264 13.334 13.6873 13.334 13.3337V8.66699"
          stroke={color ? color : "white"}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.334 1.66714C12.5992 1.40193 12.9589 1.25293 13.334 1.25293C13.7091 1.25293 14.0688 1.40193 14.334 1.66714C14.5992 1.93236 14.7482 2.29207 14.7482 2.66714C14.7482 3.04222 14.5992 3.40193 14.334 3.66714L8.00065 10.0005L5.33398 10.6671L6.00065 8.00048L12.334 1.66714Z"
          stroke={color ? color : "white"}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_929_107942">
          <rect width="16" height="16" fill={color ? color : "white"} />
        </clipPath>
      </defs>
    </svg>
  );
}

export function SVGRefreshFeed({
  className,
  color,
}: {
  className?: string;
  color?: string;
}) {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.47822 2.87734C8.61278 2.62701 7.69802 2.59999 6.81931 2.79881C5.94061 2.99763 5.12658 3.4158 4.4532 4.01431C3.77982 4.61282 3.26902 5.37216 2.96848 6.22147C2.84565 6.56857 2.4647 6.75038 2.1176 6.62755C1.7705 6.50472 1.5887 6.12377 1.71152 5.77668C2.0872 4.71504 2.7257 3.76586 3.56742 3.01773C4.40915 2.26959 5.42668 1.74687 6.52507 1.49835C7.62345 1.24983 8.7669 1.2836 9.8487 1.59651C10.9271 1.90843 11.9092 2.48759 12.7039 3.28021L14.6667 5.12467V2.66602C14.6667 2.29783 14.9651 1.99935 15.3333 1.99935C15.7015 1.99935 16 2.29783 16 2.66602V6.66602C16 7.0342 15.7015 7.33268 15.3333 7.33268H11.3333C10.9651 7.33268 10.6667 7.0342 10.6667 6.66602C10.6667 6.29783 10.9651 5.99935 11.3333 5.99935H13.6504L11.7835 4.24489C11.7784 4.24012 11.7734 4.23528 11.7685 4.23036C11.1316 3.59315 10.3437 3.12767 9.47822 2.87734ZM0 9.33268C0 8.96449 0.298477 8.66602 0.666667 8.66602H4.66667C5.03486 8.66602 5.33333 8.96449 5.33333 9.33268C5.33333 9.70087 5.03486 9.99935 4.66667 9.99935H2.34982L4.21649 11.7532C4.22158 11.758 4.22659 11.7629 4.23152 11.7678C4.86841 12.405 5.65634 12.8705 6.52178 13.1208C7.38722 13.3711 8.30198 13.3982 9.18069 13.1993C10.0594 13.0005 10.8734 12.5823 11.5468 11.9838C12.2202 11.3853 12.731 10.626 13.0315 9.77668C13.1543 9.42958 13.5353 9.24777 13.8824 9.3706C14.2295 9.49342 14.4113 9.87437 14.2885 10.2215C13.9128 11.2831 13.2743 12.2323 12.4326 12.9804C11.5908 13.7286 10.5733 14.2513 9.47493 14.4998C8.37655 14.7483 7.2331 14.7146 6.1513 14.4016C5.07294 14.0897 4.09086 13.5106 3.29608 12.718L1.33333 10.8738V13.3327C1.33333 13.7009 1.03486 13.9993 0.666667 13.9993C0.298477 13.9993 0 13.7009 0 13.3327V9.33268Z"
        fill={color ?? "#454751"}
      />
    </svg>
  );
}

export function SVGAlert({
  className,
  fill,
}: {
  className?: string;
  fill?: string;
}) {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M5 10C5.30965 10.0004 5.61173 9.9043 5.86425 9.72508C6.11676 9.54586 6.30717 9.29243 6.409 9H3.591C3.69283 9.29243 3.88324 9.54586 4.13576 9.72508C4.38827 9.9043 4.69035 10.0004 5 10ZM8.5 6.293V4C8.5 2.3915 7.4075 1.0365 5.9275 0.629C5.781 0.26 5.423 0 5 0C4.577 0 4.219 0.26 4.0725 0.629C2.5925 1.037 1.5 2.3915 1.5 4V6.293L0.646501 7.1465C0.599979 7.19285 0.563085 7.24795 0.537943 7.30862C0.5128 7.36929 0.499905 7.43433 0.500001 7.5V8C0.500001 8.13261 0.552679 8.25979 0.646447 8.35355C0.740215 8.44732 0.867392 8.5 1 8.5H9C9.13261 8.5 9.25979 8.44732 9.35355 8.35355C9.44732 8.25979 9.5 8.13261 9.5 8V7.5C9.5001 7.43433 9.4872 7.36929 9.46206 7.30862C9.43692 7.24795 9.40002 7.19285 9.3535 7.1465L8.5 6.293Z"
        fill={fill ?? "#3942FF"}
      />
    </svg>
  );
}

export function SVGCloseTag({ color }: { color?: string }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 4L4 12"
        stroke={color ? color : "#AEBDD3"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 4L12 12"
        stroke={color ? color : "#AEBDD3"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SVGCloseTagThin({ color }: { color?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4716 3.52876C12.7319 3.78911 12.7319 4.21122 12.4716 4.47157L4.47157 12.4716C4.21122 12.7319 3.78911 12.7319 3.52876 12.4716C3.26841 12.2112 3.26841 11.7891 3.52876 11.5288L11.5288 3.52876C11.7891 3.26841 12.2112 3.26841 12.4716 3.52876Z"
        fill={color ? color : "#0F172A"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.52876 3.52876C3.78911 3.26841 4.21122 3.26841 4.47157 3.52876L12.4716 11.5288C12.7319 11.7891 12.7319 12.2112 12.4716 12.4716C12.2112 12.7319 11.7891 12.7319 11.5288 12.4716L3.52876 4.47157C3.26841 4.21122 3.26841 3.78911 3.52876 3.52876Z"
        fill={color ? color : "#0F172A"}
      />
    </svg>
  );
}

export function SVGClose({ className }: { className?: string }) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M30 10L10 30"
        stroke="#111114"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 10L30 30"
        stroke="#111114"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SVGCloseNewModal({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.1787 8.82165C31.8295 9.47253 31.8295 10.5278 31.1787 11.1787L11.1787 31.1787C10.5278 31.8295 9.47253 31.8295 8.82165 31.1787C8.17078 30.5278 8.17078 29.4725 8.82165 28.8217L28.8217 8.82165C29.4725 8.17078 30.5278 8.17078 31.1787 8.82165Z"
        fill="#0F172A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.82165 8.82165C9.47253 8.17078 10.5278 8.17078 11.1787 8.82165L31.1787 28.8217C31.8295 29.4725 31.8295 30.5278 31.1787 31.1787C30.5278 31.8295 29.4725 31.8295 28.8217 31.1787L8.82165 11.1787C8.17078 10.5278 8.17078 9.47253 8.82165 8.82165Z"
        fill="#0F172A"
      />
    </svg>
  );
}
export function SVGClearSearch({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        d="M18 6L6 18"
        stroke="#454751"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 6L18 18"
        stroke="#454751"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SVGCloseSmallIcon({ color }: { color?: string }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 6L6 18"
        stroke={color ? color : "#5E6477"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 6L18 18"
        stroke={color ? color : "#5E6477"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SVGLogOut({
  className,
  stroke,
}: {
  className?: string;
  stroke?: string;
}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M16 17L21 12L16 7"
        stroke={stroke ?? "#0F172A"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 12H9"
        stroke={stroke ?? "#0F172A"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9"
        stroke={stroke ?? "#0F172A"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SVGProfile({
  className,
  stroke,
}: {
  className?: string;
  stroke?: string;
}) {
  const router = useRouter();
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21"
        stroke={
          stroke ??
          (router.pathname === MENU_ROUTE.PROFILE ? "#3942FF" : "#111114")
        }
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
        stroke={
          stroke ??
          (router.pathname === MENU_ROUTE.PROFILE ? "#3942FF" : "#111114")
        }
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SVGPasswordIcon() {
  return (
    <svg
      className="cursor-pointer absolute top-1/2 left-2 -translate-y-1/2"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6667 7.33325H3.33333C2.59695 7.33325 2 7.93021 2 8.66659V13.3333C2 14.0696 2.59695 14.6666 3.33333 14.6666H12.6667C13.403 14.6666 14 14.0696 14 13.3333V8.66659C14 7.93021 13.403 7.33325 12.6667 7.33325Z"
        stroke="#AEBDD3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66602 7.33325V4.66659C4.66602 3.78253 5.01721 2.93468 5.64233 2.30956C6.26745 1.68444 7.11529 1.33325 7.99935 1.33325C8.8834 1.33325 9.73125 1.68444 10.3564 2.30956C10.9815 2.93468 11.3327 3.78253 11.3327 4.66659V7.33325"
        stroke="#AEBDD3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SVGShowPassword() {
  return (
    <svg
      className="absolute top-1/2 left-2 -translate-y-1/2"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6667 7.33301H3.33333C2.59695 7.33301 2 7.92997 2 8.66635V13.3331C2 14.0694 2.59695 14.6664 3.33333 14.6664H12.6667C13.403 14.6664 14 14.0694 14 13.3331V8.66635C14 7.92997 13.403 7.33301 12.6667 7.33301Z"
        stroke="#AEBDD3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 4C5 4 5.01721 2.93444 5.64233 2.30932C6.26745 1.6842 7.11529 1.33301 7.99935 1.33301C8.8834 1.33301 9.73125 1.6842 10.3564 2.30932C10.9815 2.93444 11.3327 3.78229 11.3327 4.66635V7.33301"
        stroke="#AEBDD3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SVGMailIcon() {
  return (
    <svg
      className="absolute top-1/2 left-2 -translate-y-1/2"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.66732 2.66666H13.334C14.0673 2.66666 14.6673 3.26666 14.6673 4V12C14.6673 12.7333 14.0673 13.3333 13.334 13.3333H2.66732C1.93398 13.3333 1.33398 12.7333 1.33398 12V4C1.33398 3.26666 1.93398 2.66666 2.66732 2.66666Z"
        stroke="#AEBDD3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6673 4L8.00065 8.66667L1.33398 4"
        stroke="#AEBDD3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SVGArrowDown({
  className,
  color,
}: {
  className?: string;
  color?: string;
}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6 9L12 15L18 9"
        stroke={color ? color : "#62698C"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SVGBookmark({ bookmarkActive }: any) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames("bookmark", bookmarkActive && "bookmark-active")}
    >
      <path
        d="M19 21L12 16L5 21V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V21Z"
        stroke="#454751"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SVGTrending({ isTrendingActive }: any) {
  return (
    <svg
      fill={isTrendingActive ? "#3942FF" : "#EEF0F6"}
      className="transition-all ease duration-150"
      width="24px"
      height="24px"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 16q0-3.232 1.28-6.208t3.392-5.12 5.12-3.392 6.208-1.28q3.264 0 6.24 1.28t5.088 3.392 3.392 5.12 1.28 6.208q0 3.264-1.28 6.208t-3.392 5.12-5.12 3.424-6.208 1.248-6.208-1.248-5.12-3.424-3.392-5.12-1.28-6.208zM4 16q0 3.264 1.6 6.048t4.384 4.352 6.016 1.6 6.016-1.6 4.384-4.352 1.6-6.048-1.6-6.016-4.384-4.352-6.016-1.632-6.016 1.632-4.384 4.352-1.6 6.016zM6.496 12.928l6.56-0.96 2.944-5.952 2.944 5.952 6.56 0.96-4.768 4.64 1.152 6.528-5.888-3.072-5.888 3.072 1.152-6.528z"></path>
    </svg>
  );
}
export function SVGModerated({ isModeratedActive }: any) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26 16C26 21.5229 21.5229 26 16 26C10.4772 26 6 21.5229 6 16C6 10.4772 10.4772 6 16 6C21.5229 6 26 10.4772 26 16Z"
        fill={isModeratedActive ? "#3942FF" : "#EEF0F6"}
        className="transition-all ease duration-150"
      />
      <path
        d="M28 16C28 22.6275 22.6275 28 16 28C9.37259 28 4 22.6275 4 16C4 9.37259 9.37259 4 16 4C22.6275 4 28 9.37259 28 16Z"
        stroke="#EEF0F6"
        strokeWidth="2"
      />
    </svg>
  );
}
export function SVGDashboardIcon({ stroke }: any) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 14H14V21H21V14Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 14H3V21H10V14Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 3H14V10H21V3Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 3H3V10H10V3Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SVGNewsIcon({ fill }: any) {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0213 10.6842C15.0213 11.2365 15.469 11.6842 16.0213 11.6842C16.5736 11.6842 17.0213 11.2365 17.0213 10.6842H15.0213ZM15.0213 0H2V2H15.0213V0ZM0 2V16H2V2H0ZM2 18H18V16H2V18ZM20 16V4.94737H18V16H20ZM18 2.94737H16.0213V4.94737H18V2.94737ZM15.0213 3.94737V10.6842H17.0213V3.94737H15.0213ZM15.0213 2V3.94737H17.0213V2H15.0213ZM20 4.94737C20 3.8428 19.1046 2.94737 18 2.94737V4.94737V4.94737H20ZM18 18C19.1046 18 20 17.1046 20 16H18V18ZM0 16C0 17.1046 0.895431 18 2 18V16H0ZM2 0C0.895431 0 0 0.89543 0 2H2H2V0ZM15.0213 2H17.0213C17.0213 0.895431 16.1258 0 15.0213 0V2Z"
        fill={fill}
      />
      <path
        d="M6 6C5.44772 6 5 6.44772 5 7C5 7.55228 5.44772 8 6 8V6ZM12 8C12.5523 8 13 7.55228 13 7C13 6.44772 12.5523 6 12 6V8ZM6 8H12V6H6V8Z"
        fill={fill}
      />
      <path
        d="M6 10C5.44772 10 5 10.4477 5 11C5 11.5523 5.44772 12 6 12V10ZM12 12C12.5523 12 13 11.5523 13 11C13 10.4477 12.5523 10 12 10V12ZM6 12H12V10H6V12Z"
        fill={fill}
      />
    </svg>
  );
}

export function SVGTrainingCentre({ fill }: any) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.511 21V15.4311C18.7392 14.2933 19.5958 12.8115 19.9689 11.1793C20.3419 9.54707 20.2142 7.8403 19.6022 6.28185C18.9902 4.72339 17.9226 3.38569 16.5386 2.44342C15.1546 1.50115 13.5186 0.998119 11.8443 1.00001C5.49856 1.00001 4.13323 6.22489 1.86656 12.0933C1.8278 12.1944 1.81418 12.3033 1.82687 12.4107C1.83957 12.5182 1.87819 12.621 1.93941 12.7102C2.00064 12.7994 2.08263 12.8724 2.17834 12.9229C2.27404 12.9734 2.38058 12.9998 2.48878 13H4.17767V15.6667C4.17767 16.3739 4.45862 17.0522 4.95872 17.5523C5.45882 18.0524 6.13709 18.3333 6.84434 18.3333H8.17767V21"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.68239 7.17776C8.59656 7.13278 8.52153 7.06968 8.46251 6.99283C8.40349 6.91597 8.36189 6.82721 8.34058 6.73268C8.31927 6.63814 8.31877 6.54011 8.33911 6.44537C8.35946 6.35063 8.40015 6.26144 8.45839 6.18399C9.12506 5.3044 10.0818 4.68951 11.1589 4.44849C12.2359 4.20746 13.3635 4.35588 14.3415 4.86741C15.3195 5.37894 16.0846 6.22048 16.5009 7.24262C16.9173 8.26476 16.9579 9.40137 16.6157 10.4507C16.5857 10.5428 16.5359 10.6273 16.4698 10.6983C16.4038 10.7692 16.3231 10.8249 16.2333 10.8615C16.1435 10.8981 16.0468 10.9146 15.95 10.91C15.8531 10.9053 15.7585 10.8796 15.6726 10.8347L8.68239 7.17776Z"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SVGMyBookmarks({ fill }: any) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 21L12 16L5 21V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V21Z"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SVGCaseStudiesIcon({ fill }: any) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.24 12.2395C21.3658 11.1137 21.9983 9.58673 21.9983 7.99455C21.9983 6.40236 21.3658 4.87539 20.24 3.74955C19.1142 2.6237 17.5872 1.99121 15.995 1.99121C14.4028 1.99121 12.8758 2.6237 11.75 3.74955L5 10.4995V18.9995H13.5L20.24 12.2395Z"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 8L2 22"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 15H9"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SVGTopicGlossaryIcon({ stroke }: any) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.59 13.41L13.42 20.58C13.2343 20.766 13.0137 20.9135 12.7709 21.0141C12.5281 21.1148 12.2678 21.1666 12.005 21.1666C11.7422 21.1666 11.4819 21.1148 11.2391 21.0141C10.9963 20.9135 10.7757 20.766 10.59 20.58L2 12V2H12L20.59 10.59C20.9625 10.9647 21.1716 11.4716 21.1716 12C21.1716 12.5284 20.9625 13.0353 20.59 13.41V13.41Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 7H7.01"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SVGFeedsIcon({ stroke }: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
    >
      <g id="surface1">
        <path
          fill="none"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke={stroke}
          strokeOpacity="1"
          strokeMiterlimit="4"
          d="M 5 11 C 9.417969 11 13 14.582031 13 19 M 5 5 C 12.730469 5 19 11.269531 19 19 M 7 18 C 7 18.550781 6.550781 19 6 19 C 5.449219 19 5 18.550781 5 18 C 5 17.449219 5.449219 17 6 17 C 6.550781 17 7 17.449219 7 18 Z M 7 18 "
        />
      </g>
    </svg>
  );
}

export function SVGNewsletterIcon({ stroke }: any) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 6L12 13L2 6"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function NotificationCentreIcon({ stroke, isAlert, isActivePage }: any) {
  return (
    <div className="relative">
      {isAlert && (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute -top-[2px] -right-1"
        >
          <circle
            cx="6"
            cy="6"
            r="5"
            fill="#EB5757"
            stroke={isActivePage ? "#5182FF" : ""}
            strokeWidth="2"
          />
        </svg>
      )}

      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21 8V21H3V8"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 12H14"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23 3H1V8H23V3Z"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

export function SVGComment() {
  return (
    <svg
      width="32px"
      height="32px"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.991 4.8C20.991 3.81 20.19 3 19.2 3H4.8C3.81 3 3 3.81 3 4.8V15.6C3 16.59 3.81 17.4 4.8 17.4H17.4L21 21L20.991 4.8ZM12.75 13H11.25V10.75H9V9.25H11.25V7H12.75V9.25H15V10.75H12.75V13Z"
        fill="#3942FF"
      />
    </svg>
  );
}

export function SVGSearch({
  color,
  className,
}: {
  color?: string;
  className?: string;
}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
        stroke={color ? color : "#454751"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0004 21.0004L16.6504 16.6504"
        stroke={color ? color : "#454751"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SVGSmallSearch({
  color,
  className,
}: {
  color?: string;
  className?: string;
}) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ""}
    >
      <path
        d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z"
        stroke={color ? color : "#9396A5"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0002 14.0001L11.1002 11.1001"
        stroke={color ? color : "#9396A5"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SVGCheckboxArrow() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3334 4L6.00008 11.3333L2.66675 8"
        stroke="#3942FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SVGCalendar({ classNameStroke }: { classNameStroke?: string }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNameStroke}
    >
      <path
        d="M12.6667 2.66663H3.33333C2.59695 2.66663 2 3.26358 2 3.99996V13.3333C2 14.0697 2.59695 14.6666 3.33333 14.6666H12.6667C13.403 14.6666 14 14.0697 14 13.3333V3.99996C14 3.26358 13.403 2.66663 12.6667 2.66663Z"
        stroke={classNameStroke ? "" : "#454751"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 6.66663H14"
        stroke={classNameStroke ? "" : "#454751"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6665 1.33337V4.00004"
        stroke={classNameStroke ? "" : "#454751"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.3335 1.33337V4.00004"
        stroke={classNameStroke ? "" : "#454751"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SVGOrderArrow({
  color,
  className,
}: {
  color?: string;
  className?: string;
}) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8 3.33398V12.6673"
        stroke={color ? color : "#454751"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6654 8L7.9987 12.6667L3.33203 8"
        stroke={color ? color : "#454751"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SVGEmptySavedFilters() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 12H16L14 15H10L8 12H2"
        stroke="#454751"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.45 5.11L2 12V18C2 18.5304 2.21071 19.0391 2.58579 19.4142C2.96086 19.7893 3.46957 20 4 20H20C20.5304 20 21.0391 19.7893 21.4142 19.4142C21.7893 19.0391 22 18.5304 22 18V12L18.55 5.11C18.3844 4.77679 18.1292 4.49637 17.813 4.30028C17.4967 4.10419 17.1321 4.0002 16.76 4H7.24C6.86792 4.0002 6.50326 4.10419 6.18704 4.30028C5.87083 4.49637 5.61558 4.77679 5.45 5.11V5.11Z"
        stroke="#454751"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SVGArrowRight() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon/arrow-down">
        <path
          id="Vector"
          d="M3.3335 8H12.6668"
          stroke="#3942FF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M8 3.33317L12.6667 7.99984L8 12.6665"
          stroke="#3942FF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export function SVGBigArrowRight() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 12H19"
        stroke="#3942FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 5L19 12L12 19"
        stroke="#3942FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SVGArrowBack() {
  return (
    <>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="stroke-text-black hover:stroke-white cursor-pointer h-8 w-8 p-2 hover:bg-bg-navy rounded-full transition-all box-border"
      >
        <g id="Icon">
          <path
            id="Vector"
            d="M12.6666 8H3.33325"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M7.99992 12.6663L3.33325 7.99967L7.99992 3.33301"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </>
  );
}

export function SVGTrendingStar({ isActive }: { isActive: boolean }) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(
        "cursor-pointer rounded-xl ease duration-150 hover:bg-bg-gray h-8 w-8 hover:stroke-[#3942FF] p-1",
        isActive
          ? "fill-[#3942FF] stroke-[#3942FF]"
          : "fill-white stroke-[#9396A5]"
      )}
    >
      <g id="Icon/star">
        <path
          id="Vector"
          d="M12 2.5L15.09 8.76L22 9.77L17 14.64L18.18 21.52L12 18.27L5.82 21.52L7 14.64L2 9.77L8.91 8.76L12 2.5Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export function SVGBlueStar({ isActive }: { isActive?: boolean }) {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill={!isActive ? "none" : "#3942FF"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon/star">
        <path
          id="Vector"
          d="M15.9998 3.1665L20.1198 11.5132L29.3332 12.8598L22.6665 19.3532L24.2398 28.5265L15.9998 24.1932L7.75984 28.5265L9.33317 19.3532L2.6665 12.8598L11.8798 11.5132L15.9998 3.1665Z"
          stroke="#3942FF"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export function SVGNewsfeed() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0142 4.6316H13.3333C13.7015 4.6316 14 4.93008 14 5.29827V12.6667C14 13.0349 13.7015 13.3334 13.3333 13.3334H2.66667C2.29848 13.3334 2 13.0349 2 12.6667V3.33335C2 2.96516 2.29848 2.66669 2.66667 2.66669H11.3475C11.7157 2.66669 12.0142 2.96516 12.0142 3.33335V4.6316ZM12.0142 4.6316V9.12283"
        stroke="white"
        strokeWidth="1.33333"
        strokeLinecap="round"
      />
      <path
        d="M5.33334 6.66669H9.33334"
        stroke="white"
        strokeWidth="1.33333"
        strokeLinecap="round"
      />
      <path
        d="M5.33334 9.33337H9.33334"
        stroke="white"
        strokeWidth="1.33333"
        strokeLinecap="round"
      />
    </svg>
  );
}
export function SVGNewsletterAlerts() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 5.33331V14H2V5.33331"
        stroke="white"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66666 8H9.33332"
        stroke="white"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3333 2H0.666656V5.33333H15.3333V2Z"
        stroke="white"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export function SVGCaseStudies() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2935 7.36661L8.16685 13.4933C7.41629 14.2438 6.39831 14.6655 5.33685 14.6655C4.27539 14.6655 3.25741 14.2438 2.50685 13.4933C1.75629 12.7427 1.33463 11.7247 1.33463 10.6633C1.33463 9.60182 1.75629 8.58384 2.50685 7.83328L8.63352 1.70661C9.13389 1.20623 9.81255 0.925125 10.5202 0.925125C11.2278 0.925125 11.9065 1.20623 12.4068 1.70661C12.9072 2.20698 13.1883 2.88564 13.1883 3.59327C13.1883 4.30091 12.9072 4.97957 12.4068 5.47994L6.27352 11.6066C6.02333 11.8568 5.684 11.9974 5.33018 11.9974C4.97636 11.9974 4.63704 11.8568 4.38685 11.6066C4.13666 11.3564 3.99611 11.0171 3.99611 10.6633C3.99611 10.3095 4.13666 9.97013 4.38685 9.71994L10.0468 4.06661"
        stroke="white"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export function SVGMenu({ isOpen }: { isOpen: boolean }) {
  if (isOpen) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
      >
        <path
          d="M6.5 18L18.5 6M6.5 6L18.5 18"
          stroke="#fff"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
      >
        <path
          d="M4.5 6H20.5M4.5 12H20.5M4.5 18H20.5"
          stroke="#454751"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
}

export function DeleteSVG({ color }: { color?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        className={"transition-all ease duration-200"}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.3335 4.00065C1.3335 3.63246 1.63197 3.33398 2.00016 3.33398H14.0002C14.3684 3.33398 14.6668 3.63246 14.6668 4.00065C14.6668 4.36884 14.3684 4.66732 14.0002 4.66732H2.00016C1.63197 4.66732 1.3335 4.36884 1.3335 4.00065Z"
        fill={color ? color : "#9396A5"}
      />
      <path
        className={"transition-all ease duration-200"}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.6665 1.99935C6.48969 1.99935 6.32012 2.06959 6.1951 2.19461C6.07007 2.31964 5.99984 2.4892 5.99984 2.66602V3.33268H9.99984V2.66602C9.99984 2.4892 9.9296 2.31964 9.80457 2.19461C9.67955 2.06959 9.50998 1.99935 9.33317 1.99935H6.6665ZM11.3332 3.33268V2.66602C11.3332 2.13558 11.1225 1.62687 10.7474 1.2518C10.3723 0.876729 9.8636 0.666016 9.33317 0.666016H6.6665C6.13607 0.666016 5.62736 0.876729 5.25229 1.2518C4.87722 1.62687 4.6665 2.13558 4.6665 2.66602V3.33268H3.33317C2.96498 3.33268 2.6665 3.63116 2.6665 3.99935V13.3327C2.6665 13.8631 2.87722 14.3718 3.25229 14.7469C3.62736 15.122 4.13607 15.3327 4.6665 15.3327H11.3332C11.8636 15.3327 12.3723 15.122 12.7474 14.7469C13.1225 14.3718 13.3332 13.8631 13.3332 13.3327V3.99935C13.3332 3.63116 13.0347 3.33268 12.6665 3.33268H11.3332ZM3.99984 4.66602V13.3327C3.99984 13.5095 4.07007 13.6791 4.1951 13.8041C4.32012 13.9291 4.48969 13.9993 4.6665 13.9993H11.3332C11.51 13.9993 11.6795 13.9291 11.8046 13.8041C11.9296 13.6791 11.9998 13.5095 11.9998 13.3327V4.66602H3.99984Z"
        fill={color ? color : "#9396A5"}
      />
      <path
        className={"transition-all ease duration-200"}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.33317 6.66602C9.70136 6.66602 9.99984 6.96449 9.99984 7.33268V11.3327C9.99984 11.7009 9.70136 11.9993 9.33317 11.9993C8.96498 11.9993 8.6665 11.7009 8.6665 11.3327V7.33268C8.6665 6.96449 8.96498 6.66602 9.33317 6.66602Z"
        fill={color ? color : "#9396A5"}
      />
      <path
        className={"transition-all ease duration-200"}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66667 6.66602C7.03486 6.66602 7.33333 6.96449 7.33333 7.33268V11.3327C7.33333 11.7009 7.03486 11.9993 6.66667 11.9993C6.29848 11.9993 6 11.7009 6 11.3327V7.33268C6 6.96449 6.29848 6.66602 6.66667 6.66602Z"
        fill={color ? color : "#9396A5"}
      />
    </svg>
  );
}

export function PlusSVG({
  color,
  className,
}: {
  color?: string;
  className?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0001 3.33398C10.4603 3.33398 10.8334 3.70708 10.8334 4.16732V15.834C10.8334 16.2942 10.4603 16.6673 10.0001 16.6673C9.53984 16.6673 9.16675 16.2942 9.16675 15.834V4.16732C9.16675 3.70708 9.53984 3.33398 10.0001 3.33398Z"
        fill={color ? color : "white"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33325 9.99935C3.33325 9.53911 3.70635 9.16602 4.16659 9.16602H15.8333C16.2935 9.16602 16.6666 9.53911 16.6666 9.99935C16.6666 10.4596 16.2935 10.8327 15.8333 10.8327H4.16659C3.70635 10.8327 3.33325 10.4596 3.33325 9.99935Z"
        fill={color ? color : "white"}
      />
    </svg>
  );
}

export function ArrowFilterBack() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect width="24" height="24" rx="12" fill="white" />
      <path
        d="M15 6.70459C14.8132 6.51734 14.5595 6.41211 14.295 6.41211C14.0305 6.41211 13.7769 6.51734 13.59 6.70459L9.00002 11.2946C8.61002 11.6846 8.61002 12.3146 9.00002 12.7046L13.59 17.2946C13.98 17.6846 14.61 17.6846 15 17.2946C15.39 16.9046 15.39 16.2746 15 15.8846L11.12 11.9946L15 8.11459C15.39 7.72459 15.38 7.08459 15 6.70459Z"
        fill="#111114"
      />
    </svg>
  );
}

export function SVGInfo({
  hovered,
  defaultColor,
  className,
}: {
  hovered?: boolean;
  defaultColor?: string;
  className?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <path
        d="M7.3335 4.66732H8.66683V6.00065H7.3335V4.66732ZM7.3335 7.33398H8.66683V11.334H7.3335V7.33398ZM8.00016 1.33398C4.32016 1.33398 1.3335 4.32065 1.3335 8.00065C1.3335 11.6807 4.32016 14.6673 8.00016 14.6673C11.6802 14.6673 14.6668 11.6807 14.6668 8.00065C14.6668 4.32065 11.6802 1.33398 8.00016 1.33398ZM8.00016 13.334C5.06016 13.334 2.66683 10.9407 2.66683 8.00065C2.66683 5.06065 5.06016 2.66732 8.00016 2.66732C10.9402 2.66732 13.3335 5.06065 13.3335 8.00065C13.3335 10.9407 10.9402 13.334 8.00016 13.334Z"
        fill={hovered ? "#3942FF" : defaultColor ? defaultColor : "#9396A5"}
      />
    </svg>
  );
}

export function SVGGuideStep({ active }: { active: boolean }) {
  if (active) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <rect width="24" height="24" rx="12" fill="#3942FF" />
        <path d="M7 11L10.5 15L16.5 8" stroke="white" />
      </svg>
    );
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <rect width="16" height="16" rx="8" fill="#EAF0FF" />
        <path
          d="M4.66699 7.3335L7.00033 10.0002L11.0003 5.3335"
          stroke="#3942FF"
          strokeWidth="0.666667"
        />
      </svg>
    );
  }
}

export function EditAvatar({ active }: { active: boolean }) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer"
    >
      <rect
        x="1"
        y="1"
        width="26"
        height="26"
        rx="13"
        fill={active ? "#9396A5" : "#EEF0F6"}
        className="transition-all"
      />
      <g clipPath="url(#clip0_5770_187874)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.5 9.58594C17.38 9.58594 17.2611 9.60958 17.1502 9.65553C17.0392 9.70147 16.9385 9.76881 16.8536 9.8537L10.1975 16.5098L9.71262 18.2875L11.4903 17.8027L18.1465 11.1466C18.2314 11.0617 18.2987 10.9609 18.3446 10.85C18.3906 10.7391 18.4142 10.6202 18.4142 10.5002C18.4142 10.3801 18.3906 10.2612 18.3446 10.1503C18.2987 10.0394 18.2314 9.9386 18.1465 9.8537C18.0616 9.76881 17.9608 9.70147 17.8499 9.65553C17.7389 9.60958 17.6201 9.58594 17.5 9.58594ZM16.7675 8.73165C16.9997 8.63545 17.2486 8.58594 17.5 8.58594C17.7514 8.58594 18.0003 8.63545 18.2325 8.73165C18.4648 8.82785 18.6758 8.96885 18.8536 9.1466C19.0313 9.32435 19.1723 9.53537 19.2685 9.76761C19.3647 9.99986 19.4142 10.2488 19.4142 10.5002C19.4142 10.7515 19.3647 11.0004 19.2685 11.2327C19.1723 11.4649 19.0313 11.676 18.8536 11.8537L12.1036 18.6037C12.042 18.6652 11.9655 18.7096 11.8816 18.7325L9.13157 19.4825C8.95846 19.5297 8.77333 19.4806 8.64646 19.3537C8.51958 19.2268 8.47042 19.0417 8.51763 18.8686L9.26763 16.1186C9.29052 16.0346 9.33493 15.9581 9.39646 15.8966L16.1465 9.1466C16.3242 8.96885 16.5352 8.82785 16.7675 8.73165Z"
          fill={active ? "#fff" : "#454751"}
          className="transition-all"
        />
      </g>
      <rect
        x="1"
        y="1"
        width="26"
        height="26"
        rx="13"
        stroke="white"
        strokeWidth="2"
      />
      <defs>
        <clipPath id="clip0_5770_187874">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export function SVGVoteUp({
  className,
  markedAsByUser = false,
}: {
  className?: string;
  markedAsByUser?: boolean;
}) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z"
        fill={markedAsByUser ? "#3942FF" : "#454751"}
      />
      {markedAsByUser && (
        <path
          d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
          fill="#3942FF"
        />
      )}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2929 7.29289C11.6834 6.90237 12.3166 6.90237 12.7071 7.29289L16.7071 11.2929C17.0976 11.6834 17.0976 12.3166 16.7071 12.7071C16.3166 13.0976 15.6834 13.0976 15.2929 12.7071L12 9.41421L8.70711 12.7071C8.31658 13.0976 7.68342 13.0976 7.29289 12.7071C6.90237 12.3166 6.90237 11.6834 7.29289 11.2929L11.2929 7.29289Z"
        fill={markedAsByUser ? "#fff" : "#454751"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 7C12.5523 7 13 7.44772 13 8V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V8C11 7.44772 11.4477 7 12 7Z"
        fill={markedAsByUser ? "#fff" : "#454751"}
      />
    </svg>
  );
}

export function SVGVoteDown({
  className,
  markedAsByUser = false,
}: {
  className?: string;
  markedAsByUser?: boolean;
}) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z"
        fill={markedAsByUser ? "#3942FF" : "#454751"}
      />
      {markedAsByUser && (
        <path
          d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
          fill="#3942FF"
        />
      )}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.29289 11.2929C7.68342 10.9024 8.31658 10.9024 8.70711 11.2929L12 14.5858L15.2929 11.2929C15.6834 10.9024 16.3166 10.9024 16.7071 11.2929C17.0976 11.6834 17.0976 12.3166 16.7071 12.7071L12.7071 16.7071C12.3166 17.0976 11.6834 17.0976 11.2929 16.7071L7.29289 12.7071C6.90237 12.3166 6.90237 11.6834 7.29289 11.2929Z"
        fill={markedAsByUser ? "#fff" : "#454751"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 7C12.5523 7 13 7.44772 13 8V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V8C11 7.44772 11.4477 7 12 7Z"
        fill={markedAsByUser ? "#fff" : "#454751"}
      />
    </svg>
  );
}

export function SVGPageBack() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711L10.4142 12L15.7071 17.2929C16.0976 17.6834 16.0976 18.3166 15.7071 18.7071C15.3166 19.0976 14.6834 19.0976 14.2929 18.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L14.2929 5.29289C14.6834 4.90237 15.3166 4.90237 15.7071 5.29289Z"
        fill="#0F172A"
      />
    </svg>
  );
}

export function SVGFilter({
  color,
  className,
}: {
  color?: string;
  className?: string;
}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.0929 2.57912C1.25675 2.22596 1.61069 2 2.00001 2H22C22.3893 2 22.7433 2.22596 22.9071 2.57912C23.071 2.93229 23.015 3.34845 22.7636 3.64573L15 12.8261V21C15 21.3466 14.8206 21.6684 14.5257 21.8507C14.2309 22.0329 13.8628 22.0494 13.5528 21.8944L9.5528 19.8944C9.21402 19.725 9.00001 19.3788 9.00001 19V12.8261L1.23644 3.64573C0.985046 3.34845 0.929037 2.93229 1.0929 2.57912ZM4.15532 4L10.7636 11.8143C10.9162 11.9948 11 12.2236 11 12.46V18.382L13 19.382V12.46C13 12.2236 13.0838 11.9948 13.2364 11.8143L19.8447 4H4.15532Z"
        fill={color ?? "white"}
      />
    </svg>
  );
}

export function SVGActivityLog() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 18H21"
        stroke="#454751"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 18H3.01"
        stroke="#454751"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 12H21"
        stroke="#454751"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 12H3.01"
        stroke="#454751"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 6H21"
        stroke="#454751"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 6H3.01"
        stroke="#454751"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SVGEditLog({ className }: { className?: string }) {
  return (
    <svg
      className={`${className} rounded-full svg-edit-log`}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11810_150)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.5 9.58594C17.38 9.58594 17.2611 9.60958 17.1502 9.65553C17.0392 9.70147 16.9385 9.76881 16.8536 9.8537L10.1975 16.5098L9.71262 18.2875L11.4903 17.8027L18.1465 11.1466C18.2314 11.0617 18.2987 10.9609 18.3446 10.85C18.3906 10.7391 18.4142 10.6202 18.4142 10.5002C18.4142 10.3801 18.3906 10.2612 18.3446 10.1503C18.2987 10.0394 18.2314 9.9386 18.1465 9.8537C18.0616 9.76881 17.9608 9.70147 17.8499 9.65553C17.7389 9.60958 17.6201 9.58594 17.5 9.58594ZM16.7675 8.73165C16.9997 8.63545 17.2486 8.58594 17.5 8.58594C17.7514 8.58594 18.0003 8.63545 18.2325 8.73165C18.4648 8.82785 18.6758 8.96885 18.8536 9.1466C19.0313 9.32435 19.1723 9.53537 19.2685 9.76761C19.3647 9.99986 19.4142 10.2488 19.4142 10.5002C19.4142 10.7515 19.3647 11.0004 19.2685 11.2327C19.1723 11.4649 19.0313 11.676 18.8536 11.8537L12.1036 18.6037C12.042 18.6652 11.9655 18.7096 11.8816 18.7325L9.13157 19.4825C8.95846 19.5297 8.77333 19.4806 8.64646 19.3537C8.51958 19.2268 8.47042 19.0417 8.51763 18.8686L9.26763 16.1186C9.29052 16.0346 9.33493 15.9581 9.39646 15.8966L16.1465 9.1466C16.3242 8.96885 16.5352 8.82785 16.7675 8.73165Z"
          fill="#454751"
        />
      </g>
      <defs>
        <clipPath id="clip0_11810_150">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export function SVGRelevant({ className }: { className?: string }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_11847_11406)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.0013 1.99935C4.68759 1.99935 2.0013 4.68564 2.0013 7.99935C2.0013 11.3131 4.68759 13.9993 8.0013 13.9993C11.315 13.9993 14.0013 11.3131 14.0013 7.99935C14.0013 4.68564 11.315 1.99935 8.0013 1.99935ZM0.667969 7.99935C0.667969 3.94926 3.95121 0.666016 8.0013 0.666016C12.0514 0.666016 15.3346 3.94926 15.3346 7.99935C15.3346 12.0494 12.0514 15.3327 8.0013 15.3327C3.95121 15.3327 0.667969 12.0494 0.667969 7.99935ZM4.86323 7.52794L7.5299 4.86128C7.79025 4.60093 8.21236 4.60093 8.47271 4.86128L11.1394 7.52794C11.3997 7.78829 11.3997 8.2104 11.1394 8.47075C10.879 8.7311 10.4569 8.7311 10.1966 8.47075L8.66797 6.94216V10.666C8.66797 11.0342 8.36949 11.3327 8.0013 11.3327C7.63311 11.3327 7.33463 11.0342 7.33463 10.666V6.94216L5.80604 8.47075C5.54569 8.7311 5.12358 8.7311 4.86323 8.47075C4.60288 8.2104 4.60288 7.78829 4.86323 7.52794Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_11847_11406">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function SVGArrowScrollRight({ className }: { className?: string }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.29289 5.29289C8.68342 4.90237 9.31658 4.90237 9.70711 5.29289L15.7071 11.2929C16.0976 11.6834 16.0976 12.3166 15.7071 12.7071L9.70711 18.7071C9.31658 19.0976 8.68342 19.0976 8.29289 18.7071C7.90237 18.3166 7.90237 17.6834 8.29289 17.2929L13.5858 12L8.29289 6.70711C7.90237 6.31658 7.90237 5.68342 8.29289 5.29289Z"
        fill="#454751"
      />
    </svg>
  );
}

export function SVGPenEdit() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_12752_22595)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.50001 1.58594C9.37996 1.58594 9.26108 1.60958 9.15016 1.65553C9.03924 1.70147 8.93846 1.76881 8.85357 1.8537L2.19746 8.50981L1.71262 10.2875L3.49035 9.80271L10.1465 3.1466C10.2314 3.0617 10.2987 2.96092 10.3446 2.85001C10.3906 2.73909 10.4142 2.62021 10.4142 2.50015C10.4142 2.38009 10.3906 2.26121 10.3446 2.1503C10.2987 2.03938 10.2314 1.9386 10.1465 1.8537C10.0616 1.76881 9.96078 1.70147 9.84987 1.65553C9.73895 1.60958 9.62007 1.58594 9.50001 1.58594ZM8.76747 0.731648C8.99972 0.63545 9.24863 0.585938 9.50001 0.585938C9.75139 0.585938 10.0003 0.63545 10.2325 0.731648C10.4648 0.827846 10.6758 0.968846 10.8536 1.1466C11.0313 1.32435 11.1723 1.53537 11.2685 1.76761C11.3647 1.99986 11.4142 2.24877 11.4142 2.50015C11.4142 2.75153 11.3647 3.00044 11.2685 3.23269C11.1723 3.46493 11.0313 3.67595 10.8536 3.8537L4.10356 10.6037C4.04204 10.6652 3.96552 10.7096 3.88157 10.7325L1.13157 11.4825C0.958464 11.5297 0.773333 11.4806 0.646458 11.3537C0.519583 11.2268 0.470419 11.0417 0.51763 10.8686L1.26763 8.11859C1.29052 8.03465 1.33493 7.95813 1.39646 7.8966L8.14646 1.1466C8.32421 0.968846 8.53523 0.827846 8.76747 0.731648Z"
          fill="#454751"
        />
      </g>
      <defs>
        <clipPath id="clip0_12752_22595">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function SVGOpenFolder() {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.8764 4.18912C8.42946 4.58825 8.42946 5.41175 7.87641 5.81088L4.58521 8.1861C3.92384 8.6634 3 8.19083 3 7.37522L3 2.62478C3 1.80917 3.92384 1.3366 4.58521 1.8139L7.8764 4.18912Z"
        fill="#474D5A"
      />
    </svg>
  );
}

export function SVGMinimizeMenu({ stroke }: { stroke?: string }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 17L6 12L11 7"
        stroke={stroke ?? "#111114"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 17L13 12L18 7"
        stroke={stroke ?? "#111114"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SVGExportFailed() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2888 3.85996L1.8188 18C1.64417 18.3024 1.55177 18.6453 1.55079 18.9945C1.54981 19.3437 1.64029 19.6871 1.81323 19.9905C1.98616 20.2939 2.23553 20.5467 2.53651 20.7238C2.83749 20.9009 3.1796 20.9961 3.5288 21H20.4688C20.818 20.9961 21.1601 20.9009 21.4611 20.7238C21.7621 20.5467 22.0114 20.2939 22.1844 19.9905C22.3573 19.6871 22.4478 19.3437 22.4468 18.9945C22.4458 18.6453 22.3534 18.3024 22.1788 18L13.7088 3.85996C13.5305 3.56607 13.2795 3.32308 12.98 3.15444C12.6805 2.98581 12.3425 2.89722 11.9988 2.89722C11.6551 2.89722 11.3171 2.98581 11.0176 3.15444C10.7181 3.32308 10.4671 3.56607 10.2888 3.85996V3.85996Z"
        stroke="#E14545"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 9V13"
        stroke="#E14545"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 17H12.01"
        stroke="#E14545"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SVGExportSuccess() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7071 5.29289C21.0976 5.68342 21.0976 6.31658 20.7071 6.70711L9.70711 17.7071C9.31658 18.0976 8.68342 18.0976 8.29289 17.7071L3.29289 12.7071C2.90237 12.3166 2.90237 11.6834 3.29289 11.2929C3.68342 10.9024 4.31658 10.9024 4.70711 11.2929L9 15.5858L19.2929 5.29289C19.6834 4.90237 20.3166 4.90237 20.7071 5.29289Z"
        fill="#0F172A"
      />
    </svg>
  );
}

export function SVGExportLoading() {
  return (
    <svg
      className="export-loading"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 2C13 1.44772 12.5523 1 12 1C11.4477 1 11 1.44772 11 2V6C11 6.55228 11.4477 7 12 7C12.5523 7 13 6.55228 13 6V2Z"
        fill="#454751"
      />
      <path
        d="M19.7773 5.63679C20.1679 5.24627 20.1679 4.6131 19.7773 4.22258C19.3868 3.83206 18.7537 3.83206 18.3631 4.22258L15.5331 7.05258C15.1426 7.44311 15.1426 8.07627 15.5331 8.46679C15.9237 8.85732 16.5568 8.85732 16.9473 8.46679L19.7773 5.63679Z"
        fill="#454751"
      />
      <path
        d="M1 12C1 11.4477 1.44772 11 2 11H6C6.55228 11 7 11.4477 7 12C7 12.5523 6.55228 13 6 13H2C1.44772 13 1 12.5523 1 12Z"
        fill="#454751"
      />
      <path
        d="M18 11C17.4477 11 17 11.4477 17 12C17 12.5523 17.4477 13 18 13H22C22.5523 13 23 12.5523 23 12C23 11.4477 22.5523 11 22 11H18Z"
        fill="#454751"
      />
      <path
        d="M16.9473 15.5329C16.5568 15.1424 15.9237 15.1424 15.5331 15.5329C15.1426 15.9234 15.1426 16.5566 15.5331 16.9471L18.3631 19.7771C18.7537 20.1676 19.3868 20.1676 19.7773 19.7771C20.1679 19.3866 20.1679 18.7534 19.7773 18.3629L16.9473 15.5329Z"
        fill="#454751"
      />
      <path
        d="M13 18C13 17.4477 12.5523 17 12 17C11.4477 17 11 17.4477 11 18V22C11 22.5523 11.4477 23 12 23C12.5523 23 13 22.5523 13 22V18Z"
        fill="#454751"
      />
      <path
        d="M8.46679 15.5331C8.85732 15.9237 8.85732 16.5568 8.46679 16.9473L5.63679 19.7773C5.24627 20.1679 4.6131 20.1679 4.22258 19.7773C3.83206 19.3868 3.83206 18.7537 4.22258 18.3631L7.05258 15.5331C7.44311 15.1426 8.07627 15.1426 8.46679 15.5331Z"
        fill="#454751"
      />
      <path
        d="M4.22258 4.22258C4.61311 3.83206 5.24627 3.83206 5.63679 4.22258L8.46679 7.05258C8.85732 7.44311 8.85732 8.07627 8.46679 8.46679C8.07627 8.85732 7.44311 8.85732 7.05258 8.46679L4.22258 5.63679C3.83206 5.24627 3.83206 4.61311 4.22258 4.22258Z"
        fill="#454751"
      />
    </svg>
  );
}

export default function SVGSort({
  className,
  color,
}: {
  className?: string;
  color?: string;
}) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M14.3447 7.53033C14.6376 7.82322 15.1124 7.82322 15.4053 7.53033L16.125 6.81066L16.125 17C16.125 17.4142 16.4608 17.75 16.875 17.75C17.2892 17.75 17.625 17.4142 17.625 17L17.625 6.81066L18.3447 7.53033C18.6376 7.82322 19.1124 7.82322 19.4053 7.53033C19.6982 7.23744 19.6982 6.76256 19.4053 6.46967L17.4053 4.46967C17.1124 4.17678 16.6376 4.17678 16.3447 4.46967L14.3447 6.46967C14.0518 6.76256 14.0518 7.23744 14.3447 7.53033Z"
        fill={color ?? "#454751"}
      />
      <path
        d="M9.625 17.1893L10.3447 16.4697C10.6376 16.1768 11.1124 16.1768 11.4053 16.4697C11.6982 16.7626 11.6982 17.2374 11.4053 17.5303L9.40533 19.5303C9.11244 19.8232 8.63756 19.8232 8.34467 19.5303L6.34467 17.5303C6.05178 17.2374 6.05178 16.7626 6.34467 16.4697C6.63756 16.1768 7.11244 16.1768 7.40533 16.4697L8.125 17.1893L8.125 7C8.125 6.58579 8.46079 6.25 8.875 6.25C9.28921 6.25 9.625 6.58579 9.625 7L9.625 17.1893Z"
        fill={color ?? "#454751"}
      />
    </svg>
  );
}
