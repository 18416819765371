import { Theme, toast, ToastPosition } from "react-toastify";
import { logOut } from "./useLogOut";

interface Props {
  error: any;
  position?: ToastPosition;
  isWarning?: boolean;
}

const displayedErrors = new Set<string>();

export const showErrorMessage = ({
  error,
  position = "bottom-right",
  isWarning,
}: Props) => {
  const toastPosition =
    typeof window !== "undefined" && window.innerWidth <= 1024
      ? "top-center"
      : position;
  const showErrorToast = (message: string) => {
    if (!displayedErrors.has(message)) {
      const options = {
        position: toastPosition,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored" as Theme,
      };

      const toastId = isWarning
        ? toast.warning(message, options)
        : toast.error(message, options);

      displayedErrors.add(message);
      return toastId;
    }
  };

  toast.onChange(() => {
    const activeToasts = toast.isActive;
    displayedErrors.forEach((errorMessage) => {
      if (!activeToasts(errorMessage)) {
        displayedErrors.delete(errorMessage);
      }
    });
  });

  if (error?.response?.status && error.response.status !== 401) {
    if (error.response.status !== 404) {
      const errorList = error.response?.data?.errors;
      if (errorList && typeof errorList === "object") {
        if ("version_hash" in errorList) {
          errorList["version_hash"].forEach((errorMessage: string) => {
            showErrorToast(errorMessage);
          });
        } else {
          Object.keys(errorList).forEach((key) => {
            errorList[key].forEach((errorMessage: string) => {
              showErrorToast(errorMessage);
            });
          });
        }
      } else {
        const errorMessage = error.response?.data?.message;
        if (errorMessage) {
          showErrorToast(errorMessage);
        }
      }
    }
  } else if (error?.response?.status === 401) {
    logOut();
  }
};
